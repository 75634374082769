<template>
    <div class="carousel-container">
        <ol v-if="!!mediaIds && mediaIds.length > 0" ref="carouselRef" class="carousel"
            v-bind:style="'--item-size-percent: ' + ((1.0 / (visibleElements ?? 1)) * 100.0) + '%' + ((visibleElements ?? 1) > 1 ? '; --item-gap: var(--m-small)' : '')">
            <li v-for="(mediaId, i) in mediaIds" v-bind:key="mediaId"
                v-bind:ref="e => updateCarouselItemRef(e as HTMLLIElement, i)">
                <Media class="carousel-picture" image-class="carousel-image" v-bind:media-id="mediaId"
                    v-bind:use-link="useLinks" v-bind:show-caption="true" v-bind:use-preview="usePreviews" />
            </li>
        </ol>
        <DescriptionContent v-else-if="showNoMediaText" v-bind:text="noMediasText" />
        <button v-if="canScroll" class="slide-button" v-on:click="manualScrollCarousel('right')"
            aria-label="Immagine successiva">
            <i class="fa fa-caret-right"></i>
        </button>
        <button v-if="canScroll" class="slide-button" v-on:click="manualScrollCarousel('left')"
            aria-label="Immagine precedente">
            <i class="fa fa-caret-left"></i>
        </button>
        <template v-if="canScroll && !!visibleElements && !!mediaIds">
            <div v-if="mediaIds.length <= 10" class="indicators">
                <div v-for="(mediaId, i) in mediaIds" v-bind:key="mediaId" v-bind:class="getIndicatorClass(i)"></div>
            </div>
            <div v-else-if="visibleElements > 1" class="indicator-text">
                <span>{{ visibleElementIndices[0]! + 1 }} - {{ visibleElementIndices[visibleElementIndices.length - 1]!
                    + 1 }} / {{ mediaIds.length }}</span>
            </div>
            <div v-else class="indicator-text">
                <span>{{ visibleElementIndices[0]! + 1 }} / {{ mediaIds.length }}</span>
            </div>
        </template>
    </div>
</template>

<script setup lang="ts">
import { useIntersectionObserver } from '@vueuse/core';

const { mediaIds, noMediasText, visibleElements, useLinks, usePreviews, showNoMediaText = true } = defineProps<{ mediaIds?: string[], noMediasText?: string, visibleElements?: number, useLinks?: boolean, usePreviews?: boolean, showNoMediaText?: boolean }>()

const carouselRef = ref<HTMLOListElement>()
const elementRefsMap = ref<Map<string, HTMLLIElement>>(new Map())
const visibleElementIndices = ref<number[]>([])

const elementRefs = computed(() => [...elementRefsMap.value.values()])
const canScroll = computed(() => !!mediaIds && mediaIds.length > (visibleElements ?? 1))

const getIndicatorClass = (index: number) => {
    if (!visibleElementIndices.value.includes(index)) {
        return ""
    }
    let classes = "active"
    if (visibleElementIndices.value.at(0) === index) {
        classes += " first"
    }
    if (visibleElementIndices.value.at(visibleElementIndices.value.length - 1) === index) {
        classes += " last"
    }
    return classes
}

const manualScrollCarousel = (direction: "left" | "right") => {
    if (!canScroll) {
        return
    }
    const elementCount = elementRefs.value.length
    let nextElement: HTMLElement | undefined
    if (direction == "left") {
        let nextElementIndex = visibleElementIndices.value[0]! - 1
        if (nextElementIndex < 0) {
            nextElementIndex = elementCount - 1
        }
        nextElement = elementRefs.value.at(nextElementIndex)
    } else {
        let nextElementIndex = visibleElementIndices.value[0]! + 1
        if (nextElementIndex >= elementCount) {
            nextElementIndex = 0
        }
        nextElement = elementRefs.value.at(nextElementIndex)
    }
    nextElement?.scrollIntoView({ inline: "start", block: "nearest", behavior: "smooth" })
}

const updateCarouselItemRef = (ref: HTMLLIElement, index: number) => {
    const mediaId = mediaIds?.at(index)
    !!mediaId && elementRefsMap.value.set(mediaId, ref)
}

useIntersectionObserver(elementRefs, (intersections) => {
    intersections.forEach(intersection => {
        const currentElementIndex = elementRefs.value.findIndex(e => e === intersection.target)
        if (currentElementIndex < 0) {
            return
        }
        if (intersection.isIntersecting) {
            visibleElementIndices.value.push(currentElementIndex)
            visibleElementIndices.value.sort((a, b) => a - b)
        } else {
            visibleElementIndices.value = visibleElementIndices.value.filter(i => i !== currentElementIndex)
        }
    })
}, {
    root: carouselRef
})
</script>

<style scoped>
.carousel-container,
.carousel,
.carousel>li {
    position: relative;
    height: 100%;
}

.carousel,
.carousel * {
    pointer-events: painted;
}

.carousel {
    --item-size-percent: 100%;
    --item-gap: 0;

    height: 100%;
    width: 100%;
    list-style-type: none;
    text-wrap: nowrap;
    white-space: nowrap;
    overflow-x: auto;
    overflow-y: hidden;
    scroll-snap-type: x mandatory;
    scrollbar-width: none;
}

.carousel>li {
    display: inline-block;
    scroll-snap-align: start;
    width: var(--item-size-percent);
}

.carousel>li:not(:last-child) {
    margin-right: var(--item-gap);
}

:deep(.carousel-image),
.carousel-picture {
    width: 100%;
    object-fit: contain;
    aspect-ratio: 1/1;
}

.carousel-picture {
    display: inline-block;
}

/*
.carousel > li > :deep(*:not(picture *)) {
    height: 100%;
    aspect-ratio: 1/1;
}
*/
.indicator-text,
.indicators {
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    right: 0;
    margin-top: var(--m-small);
    opacity: 0.75;
}

.indicator-text {
    background-color: var(--grey-200);
    color: var(--grey-900);
    width: fit-content;
    padding: var(--m-half);
    border-radius: 4px;
    margin-inline: auto;
}

.indicators {
    display: flex;
    justify-content: center;
}

.indicators>* {
    position: relative;
    width: 8px;
    height: 8px;
    border-radius: 100vw;
    background-color: var(--grey-100);
    transition: all 250ms ease-in-out;
}

.indicators>*:not(:last-child) {
    margin-right: 2px;
}

.indicators>*.active {
    background-color: var(--primary);
    border-radius: 0;
    width: 16px;
}

.indicators>*.active.first {
    border-top-left-radius: 100vw;
    border-bottom-left-radius: 100vw;
}

.indicators>*.active.last {
    border-top-right-radius: 100vw;
    border-bottom-right-radius: 100vw;
}

.indicators>*.active:not(.last) {
    margin-right: 0;
}

.slide-button {
    display: none;
}

.slide-button:nth-child(2) {
    right: 0;
}

@media screen and (width >=1024px) {
    .carousel {
        overflow-x: hidden;
    }

    .slide-button {
        position: absolute;
        display: block;
        cursor: pointer;
        top: 50%;
        transform: translateY(-50%);
        margin-inline: var(--m-small);
        width: 48px;
        aspect-ratio: 1/1;
        background-color: var(--grey-100);
        color: var(--grey-900);
        border-radius: 100vw;
        border: 0;
    }

    .slide-button>i.fa {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        aspect-ratio: 1/1;
        font-size: var(--font-size-medium);
    }
}
</style>